import { getClubFallbackImages } from '@/shared/utils/getClubUrl';
import { useClub } from 'contexts/club';
import { useTranslations } from 'contexts/translations';
import { useAuth } from 'contexts/auth';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GoogleAnalyticsHead, AdManagerHead } from 'utils/googleAnalytics';
import { supportedLanguages } from 'utils/language';
import ImageMeta from './ImageMeta';
import TitleMeta from './TitleMeta';

const CDN_BASE_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL
function PageMetaTag() {
    const { club, font, clubSocialNetworks } = useClub()
    const { cookieSettings } = useAuth()
    const logo_name = club?.logo_name
	const logoBaseUrl = [CDN_BASE_URL, club?.id, 'logo'].join('/')
    const baseUrl = club.domain_online ?
     `https://www.${club.domain}`
    : `${process.env.NEXT_PUBLIC_APP_WEBSITE}`
    const router = useRouter()

    let path = router.asPath.split('?')[0]

    if (club.domain_online && router.query.prefix === 'web' && path.startsWith('/web/')) {
        // we need to remove /web from path to get correct url
        path = path.substring(4)
    }

    const canonicalLink = `${baseUrl}${path}`;
    const { translations: { locale } } = useTranslations()
    const clubFallbacks = getClubFallbackImages(club).slice(-1)
    const twitterProfile = clubSocialNetworks?.find(e => e.type === 'twitter')
    return (
        <>
            {/* default title */}
            <TitleMeta/>
            {/* default page image */}
            <ImageMeta image={clubFallbacks[0].src}/>
            <Head>
                <meta charSet="utf-8" key='charset'/>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" key='viewport'/>
                <meta name="theme-color" content="#ffffff" key='themecolor' />
                <meta name="mobile-web-app-capable" content="yes" key="mobile-web-app-capable" />
                <meta name="apple-mobile-web-app-capable" content="yes" key="apple-mobile-web-app-capable" />
                <meta property="og:type" content="website" key="ogtype" />
                <meta property="og:url" content={canonicalLink} key="ogurl" />
                <meta property="og:locale" content={locale} key="oglocale" />
                <meta  property="og:image:width" content="1200" key="ogimagewidth" />
                <meta  property="og:image:height" content="630" key="ogimageheight" />
                <meta name="twitter:card" content="summary_large_image" key="twittercard" />
                {/* twitter handle */}
                {twitterProfile && (
                    <meta name="twitter:site" content={twitterProfile.name} key="twittersite" />
                )}
                {logo_name ? (
                    <>
                        <link rel="apple-touch-icon" type="image/png" sizes="96x96" href={`${logoBaseUrl}/96x96_${logo_name}.png`} />
                        <link rel="icon" type="image/png" sizes="16x16" href={`${logoBaseUrl}/16x16_${logo_name}.png`} />
                    </>
                ) : (
                    <link rel="icon" type="image/x-icon" href={`${process.env.NEXT_PUBLIC_CDN}/images/favicon.ico`} sizes='16x16' />
                )}
                <link rel="preconnect" href={`${process.env.NEXT_PUBLIC_CLEAR_CDN_URL}/`} crossOrigin="anonymous" />
                <link rel="preconnect" href={`${process.env.NEXT_PUBLIC_STATIC}/`} crossOrigin="anonymous" />
                <link rel="preconnect" href="https://www.googleadservices.com" />
                <link rel="preconnect" href="https://www.googletagmanager.com" />
                <link rel="canonical" href={`${canonicalLink}`} />
                {supportedLanguages.map((suppotedLang) => {
                    if (suppotedLang === locale) {
                        return <link key={suppotedLang} rel="alternate" hrefLang="x-default" href={`${canonicalLink}/`} />
                    }
                    return <link key={suppotedLang} rel="alternate" hrefLang={suppotedLang} href={`${canonicalLink}/?lang=${suppotedLang}`} />
                })}
                <link href={`${process.env.NEXT_PUBLIC_CLUBEE_FONTS}/fontawesome.css`} rel="stylesheet" />
                {font?.file_name && <link href={`${process.env.NEXT_PUBLIC_CDN}/fonts/website_builder/css/${font.file_name}.css`} rel="stylesheet" />}
                {font?.file_name2 && font?.file_name !== font.file_name2 && <link href={`${process.env.NEXT_PUBLIC_CDN}/fonts/website_builder/css/${font.file_name2}.css`} rel="stylesheet" />}
                {cookieSettings?.analytical && <GoogleAnalyticsHead />}
                <AdManagerHead club={club} />
            </Head>
        </>
    )
}

export default PageMetaTag